import { render } from "react-dom";
import "./index.css";
import App from "./App";

if (
  localStorage.theme === "dark" ||
  (!("theme" in localStorage) &&
    window.matchMedia("(prefers-color-scheme: dark)").matches)
) {
  document.documentElement.classList.add("dark");
} else {
  document.documentElement.classList.remove("dark");
}

const Root = () => {
  const flipTheme = () => {
    localStorage.theme === "dark"
      ? document.documentElement.classList.remove("dark")
      : document.documentElement.classList.add("dark");

    localStorage.theme === "dark"
      ? (localStorage.theme = "light")
      : (localStorage.theme = "dark");
  };
  return (
    <div className="min-h-full min-w-full dark:bg-white">
      <App flipTheme={flipTheme} />
    </div>
  );
};

render(<Root />, document.getElementById("root"));
