import "./App.css";
import { NavBar } from "./shared/NavBar";
import { Home } from "./pages/Home";
import { Resume } from "./pages/Resume";
import { Projects } from "./pages/Projects";
import { Iex } from "./pages/Iex";
import { Blogs } from "./pages/Blogs";
import { SecuritySearch } from './pages/SecuritySearch';
import { TodoList } from './pages/TodoList';
import { RumbleSearch } from "./pages/RumbleSearch";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

interface AppProps {
  flipTheme: () => void;
}

console.log(`
██   ██ ███████ ██      ██       ██████  
██   ██ ██      ██      ██      ██    ██ 
███████ █████   ██      ██      ██    ██ 
██   ██ ██      ██      ██      ██    ██ 
██   ██ ███████ ███████ ███████  ██████  `);
console.log("Look at you being all curious...");

const App = ({ flipTheme }: AppProps) => {
  return (
    <div className="dark:bg-black bg-white">
      <Router>
        <NavBar flipTheme={flipTheme} />
        <Switch>
          <Route path="/todos">
            <TodoList />
          </Route>
          <Route path="/security-search/:ipAddress?">
            <SecuritySearch />
          </Route>
          <Route path="/blogs">
            <Blogs />
          </Route>
          <Route path="/stonks">
            <Iex />
          </Route>
          <Route path="/projects">
            <Projects />
          </Route>
          <Route path="/resume">
            <Resume />
          </Route>
          <Route path="/rumble-search">
            <RumbleSearch />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};
export default App;
