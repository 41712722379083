import { useEffect, useState } from "react";
import axios from "axios";
import { Layout } from "../shared/Layout";

const baseUrl = "https://console.rumble.run/api/v1.0/";

const getInventoryData = (apiKey: string) => {
  const uri = `org`;
  const url = `${baseUrl}${uri}`;
  axios
    .get<any[]>(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${apiKey}`,
      },
    })
    .then((r) => {
      console.log(r);
    });
};

export const RumbleSearch = () => {
  const [apiKey, setApiKey] = useState<string>("");

  useEffect(() => {
    console.log(apiKey);
  }, [apiKey]);

  return (
    <>
      <Layout
        title="Rumble Search"
        subtitle="This uses the Rumble API to give the user a guided search experience"
      >
        <div className="dark:bg-gray-900 bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium  dark:text-gray-400 text-gray-900">
              Organization Key
            </h3>
            <input
              type="text"
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
              className="focus:ring-indigo-500 focus:border-indigo-500 p-5 pl-7 w-1/2 sm:text-sm dark:border-gray-300 border-2 border-gray-300 rounded-md m-2 text-md"
              placeholder="Organization Key"
            />
          </div>
          <div className="border-t dark:border-gray-700 border-gray-200">
            <button
              onClick={() => {
                getInventoryData(apiKey);
              }}
              type="button"
              className="m-1 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <svg
                className="-ml-1 mr-2 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
              Show me my inventory
            </button>
          </div>
        </div>
      </Layout>
    </>
  );
};
