import { motion } from 'framer-motion';

export const Loading = () => {
    return (
        <motion.div
            className="dark:bg-white bg-black m-2 rounded justify-center h-10 w-10"
            animate={{ y: 70, rotate: 360 }}
            transition={{
                duration: 1,
                ease: [0.075, 0.82, 0.165, 1],
                repeat: Infinity,
                repeatType: 'reverse'
            }}
        />
    );
};


