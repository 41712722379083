import { useState, useRef } from "react";
import { motion } from "framer-motion";
import { Layout } from "../shared/Layout";
import { useFormik } from "formik";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";

interface AddTodoFormValues {
  name: string;
}

interface TodoItem {
  id: string;
  name: string;
  todoSectionId: string;
}

interface TodoSection {
  id: string;
  name: string;
  left: number;
  right: number;
}

export const TodoList = () => {
  const [isDragging, setDragging] = useState(false);
  const itemRef = useRef(null);

  let [todoSections] = useState<TodoSection[]>([
    { id: "abc", name: "Todo", left: 0, right: 0 },
    { id: "bcd", name: "In Progress", left: 0, right: 0 },
    { id: "cde", name: "Done", left: 0, right: 0 },
  ]);

  let [todoItems, setTodoItems] = useState([
    { id: "abc", todoSectionId: "cde", name: "Buy Waffle Ingredients" },
    { id: "bcd", todoSectionId: "cde", name: "Mix Waffle Ingredients" },
    { id: "cde", todoSectionId: "cde", name: "Cook Waffles" },
    { id: "def", todoSectionId: "bcd", name: "Apply Whipped Cream" },
    { id: "efg", todoSectionId: "bcd", name: "Apply Syrup" },
    { id: "fgh", todoSectionId: "abc", name: "Eat Waffles" },
  ]);

  const updateTodoItem = (id: string, dropLocation: number) => {
    const item = todoItems.find((i) => i.id === id);
    if (item) {
      const section = todoSections.find(
        (i) => i.left < dropLocation && i.right > dropLocation,
      );
      if (section) {
        item.todoSectionId = section.id;
      }
    }
    setTodoItems(todoItems);
  };

  const initialValues: AddTodoFormValues = { name: "" };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      name: Yup.string().required("Name Required"),
    }),
    onSubmit: (values) => {
      setTodoItems((todoItems: TodoItem[]) => [
        ...todoItems,
        { id: uuidv4(), todoSectionId: todoSections[0].id, name: values.name },
      ]);
    },
  });

  return (
    <>
      <Layout
        title="Todo List"
        subtitle="Draggable Todo List with Framer Motion"
      >
        <form onSubmit={formik.handleSubmit} className="m-2">
          <div className="dark:text-white text-black">New todo?</div>
          <input
            className="my-2 focus:ring-indigo-500 focus:border-indigo-500 block p-5 pl-7 pr-12 sm:text-sm dark:border-gray-300 border-2 border-gray-300 rounded-md text-md"
            id="name"
            name="name"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.name}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="text-red-500">{formik.errors.name}</div>
          ) : null}

          <button
            type="submit"
            className="my-2 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Add Todo
          </button>
        </form>
        <div className="grid grid-cols-3 gap-4">
          {todoSections.map((todoSection) => {
            return (
              <motion.div
                className="block p-4 rounded shadow center dark:bg-gray-900"
                ref={(location) => {
                  if (!location) return;
                  const section = todoSections.find(
                    (i) => i.id === todoSection.id,
                  );
                  if (section) {
                    const { left, right } = location.getBoundingClientRect();
                    section.left = left;
                    section.right = right;
                  }
                }}
              >
                <h1 className="text-3xl font-bold dark:text-gray-100 text-gray-900 my-2">
                  {todoSection.name}
                </h1>
                {todoItems.map((item) => {
                  if (item.todoSectionId === todoSection.id) {
                    return (
                      <motion.button
                        drag="x"
                        ref={itemRef}
                        initial={false}
                        className="m-1 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600"
                        dragElastic={1}
                        whileHover={{ scale: 1.03 }}
                        whileTap={{ scale: 1.12 }}
                        onDragStart={() => {
                          setDragging(true);
                          console.log("Dragging? ", isDragging);
                        }}
                        onDragEnd={(e, dropLocation) => {
                          updateTodoItem(item.id, dropLocation.point.x);
                          setDragging(false);
                          console.log("Dragging? ", isDragging);
                        }}
                        dragConstraints={{
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                        }}
                      >
                        {item.name}
                      </motion.button>
                    );
                  } else {
                    return null;
                  }
                })}
              </motion.div>
            );
          })}
        </div>
      </Layout>
    </>
  );
};
