import { useEffect, useState } from "react";
import { Layout } from "../shared/Layout";
import axios from "axios";
import { BarChart } from "reaviz";

const token = "?token=pk_174943f165c74eeea910e2473a39bf93";
let baseUrl = "https://cloud.iexapis.com";

interface SymbolType {
  symbol: string;
  date: string;
  isEnabled: boolean;
}

interface IexDataType {
  Assets: string;
  date: Date;
}

interface BarChartType {
  key: Date;
  data: number;
}

export const Iex = () => {
  const [fetched, setFetched] = useState(false);

  // symbols + filtering
  const [filterValue, setFilterValue] = useState("");
  const defaultSymbols: SymbolType[] = [];
  const [filterList, setFilterList] = useState(defaultSymbols);
  const [symbols, setSymbols] = useState(defaultSymbols);
  const filter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const enabled = symbols.filter((s) => {
        return s.isEnabled;
        // Use the toLowerCase() method to make it case-insensitive
      });
      const results = enabled.filter((s) => {
        return s.symbol.toLowerCase().startsWith(keyword.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
      });
      setFilterList(results.slice(0, 10));
    } else {
      setFilterList(symbols.slice(0, 10));
      // If the text field is empty, show all users
    }

    setFilterValue(keyword);
  };

  // get symbols list on mount
  useEffect(() => {
    const uri = "/stable/ref-data/iex/symbols";
    const url = baseUrl + uri + token;
    axios
      .get<SymbolType[]>(url)
      .then((response) => setSymbols(response?.data))
      .catch((error) => console.log(error));
    return;
  }, []);

  // data for graphing
  const defaultGraph: BarChartType[] = [];
  const [graphData, setGraphData] = useState(defaultGraph);

  const getGraphData = (pickedSymbol: string) => {
    const uri = `/stable/time-series/REPORTED_FINANCIALS/${pickedSymbol}/10-Q`;
    const url = `${baseUrl}${uri}${token}&last=10`;
    axios.get<IexDataType[]>(url).then((response) => {
      const graphData = response?.data;
      const newGraphData = graphData.map(({ date: key, Assets: data }) => ({
        key: new Date(key),
        data: Number(data),
      }));
      setGraphData(newGraphData);
    });
    setFetched(true);
  };

  return (
    <>
      <Layout title="Stonks" subtitle="Graphing some data">
        <div className="dark:text-white text-black">
          Start typing to pick a stonk...
        </div>
        <input
          type="search"
          value={filterValue}
          onChange={filter}
          className="focus:ring-indigo-500 focus:border-indigo-500 block p-5 pl-7 pr-12 sm:text-sm dark:border-gray-300 border-2 border-gray-300 rounded-md m-2 text-md"
          placeholder="Symbol"
        />
        <div className="flex flex-wrap m-2 text-md">
          {filterList.map((s) => {
            return (
              <div key={`symbol-${s.symbol}`}>
                <span className="sm:ml-3">
                  <button
                    onClick={() => {
                      getGraphData(s.symbol);
                      setFilterValue("");
                      setFilterList(defaultSymbols);
                    }}
                    type="button"
                    className="m-1 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <svg
                      className="-ml-1 mr-2 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    {s.symbol}
                  </button>
                </span>
              </div>
            );
          })}
        </div>
        {graphData.length > 0 ? (
          <>
            <BarChart
              className="m-8 justify-items-stretch"
              width={500}
              height={400}
              data={graphData}
            />
          </>
        ) : fetched ? (
          <div>Sorry, no data to display for that stonk.</div>
        ) : null}
      </Layout>
    </>
  );
};
