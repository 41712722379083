import { useState, useEffect } from "react";
import axios from "axios";
import { Layout } from "../shared/Layout";
import { Loading } from "../shared/Loading";

interface ResponseType {
    feed: Object;
    items: BlogType[];
    status: string;
}

interface BlogType {
    title: string;
    link: string;
    pubDate: string;
    thumbnail?: string;
}

export const Blogs = () => {
    const [loading, setLoading] = useState(true);
    const defaultBlogs: BlogType[] = [];
    const [blogs, setBlogs] = useState(defaultBlogs);

    useEffect(() => {
        const url =
            "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@tylerdiderich";
        axios
            .get<ResponseType>(url)
            .then((response) => {
                const blogs = response?.data?.items;
                setBlogs(blogs ? blogs : defaultBlogs);
                setLoading(false);
            })
            .catch((error) => console.log(error));
        return () => setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Layout title="Blogs" subtitle="Sometimes I Write Stuff Down">
            {loading ? (
                <Loading />
            ) : (
                <div className="dark:bg-gray-900 bg-white shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <div className="flex flex-col">
                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <table className="min-w-full divide-y dark:divide-gray-700 divide-gray-200">
                                            <thead className="dark:bg-gray-800 bg-gray-50">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium dark:text-gray-300 text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Posts
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y dark:divide-gray-700 divide-gray-200">
                                                {blogs.map((b, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                <div className="flex items-center">
                                                                    <div className="flex-shrink-0 h-10 w-10">
                                                                        <img
                                                                            className="h-10 w-10 rounded-full"
                                                                            src={b.thumbnail}
                                                                            alt="thumbnail"
                                                                        />
                                                                    </div>
                                                                    <div className="ml-4">
                                                                        <div className="text-sm font-medium dark:text-gray-400 text-gray-900">
                                                                            {b.pubDate}
                                                                        </div>
                                                                        <div className="text-sm dark:text-gray-300 text-gray-500">
                                                                            {b.title}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                                <a
                                                                    href={b.link}
                                                                    className="dark:text-indigo-400 dark:hover:text-indigo-600 text-indigo-600 hover:text-indigo-900"
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    View
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Layout>
    );
};