import { ReactNode } from "react";
import { motion } from "framer-motion";

interface LayoutProps {
  title: string;
  subtitle?: string;
  children?: ReactNode;
}

export const Layout = ({ title, subtitle, children }: LayoutProps) => {
  return (
    <motion.div
      animate={{ opacity: [0, 100] }}
      transition={{ duration: 5 }}
      className="object-center lg:mx-32 mx-4"
    >
      <header className="dark:bg-black bg-white">
        <div className="py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold dark:text-gray-100 text-gray-900">
            {title}
          </h1>
          {!!subtitle && (
            <p className="mt-1 text-md dark:text-gray-400 text-gray-500">
              {subtitle}
            </p>
          )}
        </div>
      </header>
      <main className="dark:bg-black bg-white">
        <div className="py-6 sm:px-6 lg:px-8">{children}</div>
      </main>
    </motion.div>
  );
};
