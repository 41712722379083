import { useEffect, useState } from "react";
import { Layout } from "../shared/Layout";
import { PrettyPrintJson } from "../shared/PrettyPrintJson"
import { Loading } from "../shared/Loading";
import axios from "axios";
import { useFormik } from 'formik';
import * as Yup from 'yup'
import {
    useParams,
    useHistory
} from "react-router-dom";

interface SingleSecuritySearchResponse {
    vendor: string,
    data: Object
}

interface CompleteSecuritySearchResponse {
    data?: SingleSecuritySearchResponse[]
}

type SecuritySearchFormValues = {
    ipAddress: string
}

type UrlParams = {
    ipAddress?: string;
}

export const SecuritySearch = () => {
    let urlParams = useParams<UrlParams>();
    const [loading, setLoading] = useState(!!urlParams.ipAddress ? true : false)
    const [curlCommand, setCurlCommand] = useState("")
    const defaultSecuritySearchResults: CompleteSecuritySearchResponse = {}
    const [securitySearchResults, setSecuritySearchResults] = useState(defaultSecuritySearchResults)

    const runSecuritySearch = async (ipAddress: string) => {
        setLoading(true);
        const url = "https://tylerdiderich.com/api/security-search";
        axios
            .get<CompleteSecuritySearchResponse>(url, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                }, params: { ipAddress: ipAddress }
            })
            .then((response) => {
                setSecuritySearchResults(response.data)
                setCurlCommand(`curl "https://tylerdiderich.com/api/security-search?ipAddress=${ipAddress}"`)
            })
            .then(() => setLoading(false))
            .catch((error) => console.log(error));

    };

    const history = useHistory();

    const initialValues: SecuritySearchFormValues = { ipAddress: urlParams.ipAddress || '' };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            ipAddress: Yup.string()
                .matches(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/, "Please input a valid IPv4 IP Address")
                .required('IP Address Required'),
        }),
        onSubmit: async (values) => {
            setLoading(true)
            await runSecuritySearch(values.ipAddress).then(() => {
                history.push(`/security-search/${values.ipAddress}`)
                setLoading(false)
            })

        },
    });

    useEffect(() => {
        if (urlParams.ipAddress) {
            runSecuritySearch(urlParams.ipAddress)
        }

    }, [setLoading, urlParams.ipAddress])

    return (
        <>
            <Layout title="Security API Search" subtitle="Getting Some Data from Some APIs">
                <form onSubmit={formik.handleSubmit} className="m-2" key={urlParams.ipAddress}>
                    <div className="dark:text-white text-black">
                        Input IPv4 to run security search...
                    </div>
                    <input
                        className="my-2 focus:ring-indigo-500 focus:border-indigo-500 block p-5 pl-7 pr-12 sm:text-sm dark:border-gray-300 border-2 border-gray-300 rounded-md text-md"
                        id="ipAddress"
                        name="ipAddress"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.ipAddress}
                    />
                    {formik.touched.ipAddress && formik.errors.ipAddress ? (
                        <div className="text-red-500">{formik.errors.ipAddress}</div>
                    ) : null}

                    <button type="submit" className="my-2 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Run Search
                    </button>
                </form>
                {
                    loading && <Loading />
                }
                {
                    !loading && securitySearchResults.data &&
                    <div className="m-4 dark:text-gray-100 text-gray-900">
                        <h1 className="mb-4 text-xl font-bold dark:text-gray-100 text-gray-900">Want the raw JSON?</h1>
                        <p>{curlCommand}</p>
                    </div>
                }
                {!loading && !!securitySearchResults.data && securitySearchResults.data.map((result, index) => {
                    return (
                        <div className="m-4" key={`result-${index}`}>
                            <h1 className="mb-2 text-xl font-bold dark:text-gray-100 text-gray-900">{result.vendor}</h1>
                            <PrettyPrintJson json={result.data} />
                        </div>
                    )
                })}
            </Layout>
        </>
    );
};
