import { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import 'react-toggle/style.css';

interface NavProps {
  flipTheme: () => void;
}

const activeLink =
  'dark:bg-gray-600 bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium';
const inactiveLink =
  'dark:text-gray-300 text-gray-700 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium';
const activeMobileLink =
  'dark:bg-gray-600 bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium';
const inactiveMobileLink =
  'dark:text-gray-300 text-gray-700 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium';

export const NavBar = ({ flipTheme }: NavProps) => {
  const [icon, setIcon] = useState(
    localStorage.theme === 'light'
      ? 'M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z'
      : 'M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z',
  );
  const [showMenu, setShowMenu] = useState(false);
  const [activePath, setActivePath] = useState(window.location.pathname);
  const history = useHistory();

  useEffect(() => {
    return history.listen((location) => {
      setActivePath(location.pathname);
    });
  }, [history]);

  return (
    <>
      <nav className="dark:bg-black bg-white">
        <div className="lg:mx-20 px-2 sm:px-6 lg:px-8">
          <div className="relative flex items-center justify-between h-16">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <button
                  type="button"
                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  aria-controls="mobile-menu"
                  aria-expanded="false"
                  onClick={() => setShowMenu(!showMenu)}
                >
                  <span className="sr-only">Open main menu</span>
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                  <svg
                    className="hidden h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="invisible sm:visible flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="hidden sm:block sm:ml-6">
                  <div className="flex space-x-4">
                    <NavLink className={activePath === '/' ? activeLink : inactiveLink} to="/">
                      Home
                    </NavLink>
                    <NavLink
                      className={activePath === '/resume' ? activeLink : inactiveLink}
                      to="/resume"
                    >
                      Resume
                    </NavLink>
                    <NavLink
                      className={activePath === '/projects' ? activeLink : inactiveLink}
                      to="/projects"
                    >
                      Projects
                    </NavLink>
                    <NavLink
                      className={activePath === '/blogs' ? activeLink : inactiveLink}
                      to="/blogs"
                    >
                      Blogs
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <button
                type="button"
                className="p-1 rounded-full dark:text-gray-800 text-white dark:bg-white bg-gray-800 dark:hover:text-gray-400 hover:text-gray-400"
                onClick={() => {
                  flipTheme();
                  setIcon(
                    icon ===
                      'M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z'
                      ? 'M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z'
                      : 'M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z',
                  );
                }}
              >
                <span className="sr-only">Flip Theme</span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={icon} />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <>
          {showMenu ? (
            <div className="sm:hidden" id="mobile-menu">
              <div className="px-2 pt-2 pb-3 space-y-1">
                <NavLink
                  className={activePath === '/' ? activeMobileLink : inactiveMobileLink}
                  to="/"
                  onClick={() => setShowMenu(false)}
                >
                  Home
                </NavLink>
                <NavLink
                  className={activePath === '/resume' ? activeMobileLink : inactiveMobileLink}
                  to="/resume"
                  onClick={() => setShowMenu(false)}
                >
                  Resume
                </NavLink>
                <NavLink
                  className={activePath === '/projects' ? activeMobileLink : inactiveMobileLink}
                  to="/projects"
                  onClick={() => setShowMenu(false)}
                >
                  Projects
                </NavLink>
                <NavLink
                  className={activePath === '/blogs' ? activeMobileLink : inactiveMobileLink}
                  to="/blogs"
                >
                  Blogs
                </NavLink>
              </div>
            </div>
          ) : null}
        </>
      </nav>
    </>
  );
};
