import { useEffect, useState } from "react";
import { Layout } from "../shared/Layout";
import { Loading } from "../shared/Loading";
import Gravatar from "react-gravatar";
import axios from "axios";
import { NavLink } from "react-router-dom";

interface ResponseData {
  data: ProjectType[];
}
interface ProjectType {
  name: string;
  description: string;
  status: string;
  focus: string;
  link: string;
}

export const Projects = () => {
  const [loading, setLoading] = useState(true);
  const defaultProjects: ProjectType[] = [];
  const [projects, setProjects] = useState(defaultProjects);
  useEffect(() => {
    const url = "https://tylerdiderich.com/api/projects";
    axios
      .get<ResponseData>(url, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => setProjects(response?.data?.data))
      .then(() => setLoading(false))
      .catch((error) => console.log(error));
    return () => setLoading(false);
  }, []);

  return (
    <>
      <Layout title="Projects" subtitle="Some Things I've Done">
        {loading ? (
          <Loading />
        ) : (
          <div className="dark:bg-gray-900 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                      <table className="min-w-full divide-y dark:divide-gray-700 divide-gray-200">
                        <thead className="dark:bg-gray-800 bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium dark:text-gray-300 text-gray-500 uppercase tracking-wider"
                            >
                              Project
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium dark:text-gray-300 text-gray-500 uppercase tracking-wider"
                            >
                              Status
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium dark:text-gray-300 text-gray-500 uppercase tracking-wider"
                            >
                              Focus
                            </th>
                            <th scope="col" className="relative px-6 py-3">
                              <span className="sr-only">Edit</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y dark:divide-gray-700 divide-gray-200">
                          {projects
                            .filter((p) => p.link.includes("https"))
                            .map((p, i) => {
                              return (
                                <tr key={i}>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="flex items-center">
                                      <div className="flex-shrink-0 h-10 w-10">
                                        <Gravatar
                                          email={p.name}
                                          className="h-10 w-10 rounded-full"
                                          alt={p.name}
                                        />
                                      </div>
                                      <div className="ml-4">
                                        <div className="text-sm font-medium dark:text-gray-400 text-gray-900">
                                          {p.name}
                                        </div>
                                        <div className="text-sm dark:text-gray-300 text-gray-500">
                                          {p.description}
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full dark:bg-indigo-900 dark:text-indigo-200 bg-green-100 text-green-800">
                                      {p.status}
                                    </span>
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm dark:text-gray-300 text-gray-500">
                                    {p.focus}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                    <a
                                      href={p.link}
                                      className="dark:text-indigo-400 dark:hover:text-indigo-600 text-indigo-600 hover:text-indigo-900"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                          {projects
                            .filter((p) => !!!p.link.includes("https"))
                            .map((p, i) => {
                              return (
                                <tr key={i}>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="flex items-center">
                                      <div className="flex-shrink-0 h-10 w-10">
                                        <Gravatar
                                          email={p.name}
                                          className="h-10 w-10 rounded-full"
                                          alt={p.name}
                                        />
                                      </div>
                                      <div className="ml-4">
                                        <div className="text-sm font-medium dark:text-gray-400 text-gray-900">
                                          {p.name}
                                        </div>
                                        <div className="text-sm dark:text-gray-300 text-gray-500">
                                          {p.description}
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full dark:bg-indigo-900 dark:text-indigo-200 bg-green-100 text-green-800">
                                      {p.status}
                                    </span>
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm dark:text-gray-300 text-gray-500">
                                    {p.focus}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                    <NavLink
                                      to={p.link}
                                      className="dark:text-indigo-400 dark:hover:text-indigo-600 text-indigo-600 hover:text-indigo-900"
                                    >
                                      View
                                    </NavLink>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};
